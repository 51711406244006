<template>
  <div class="list-state-group">
    <el-radio-group v-model="query.status" size="mini">
      <el-radio-button v-for="({ label, value }, index) in list" :label="value" :key="index">{{
        label
      }}</el-radio-button>
    </el-radio-group>
  </div>
</template>

<script>
import { list } from '../const'
export default {
  props: {
    query: {
      type: Object,
      required: true
    }
  },

  computed: {
    status() {
      return this.query.status
    },    
    list() {
      return list.filter((item) => item.permission)
    }
  }

}
</script>

<style lang="scss" scoped></style>
