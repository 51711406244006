import { checkPermission } from '@/utils'
import { SINGLE_PROTO_CUSTOM_FLOOR, COMBINATIONM_PROTO_CUSTOM_FLOOR } from '@/utils/constant'

export const list = [
  {
    label: '单品自定义底板',
    value: SINGLE_PROTO_CUSTOM_FLOOR,
    permission: checkPermission(['externaladmin:user:protoCustomFloor:singleCustomFloor']),
    childPermission: {}
  },

  {
    label: '组合产品自定义底板',
    value: COMBINATIONM_PROTO_CUSTOM_FLOOR,
    permission: checkPermission(['externaladmin:user:protoCustomFloor:combinationmFloor']),
    childPermission: {}
  }
]

export const singleCustomFloorCols = [
  {
    label: '原型名称',
    prop: 'name',
    search: true,
    slot: true
  },
  {
    label: '款式',
    prop: 'structName',
    search: true,
    hide: true
  },
  {
    label: '底板预览',
    prop: 'bottomBoardPreview',
    slot: true
  }
]

export const combinationmFloorCols = [
  {
    label: '组合产品',
    prop: 'combinedChineseNameLike',
    search: true,
    hide: true
  },
  {
    label: '组合产品',
    prop: 'combinedChineseName',
    slot: true
  },
  {
    label: '底板预览',
    prop: 'bottomBoardPreview',
    slot: true
  },
  {
    label: '分销商',
    prop: 'createByName',
    search: true,
    hide: true
  },
  {
    label: '编码',
    prop: 'combinedCustomProductCodeLike',
    search: true,
    hide: true
  },
  {
    label: '分销商',
    prop: 'createByName'
  },
  // {
  //   prop: 'create_time',
  //   search: true,
  //   searchSpan: 9,
  //   searchLabel: '时间',
  //   searchFormSlot: true,
  //   hide: true
  // }

  {
    label: '时间',
    prop: 'time',
    slot: true
  }
]

export const tableOption = {
  search: true,
  height: 'auto',
  menuWidth: 150,
  menuAlign: 'center',
  editBtn: false,
  delBtn: false,
  topPage: false,
  searchSpan: 6,
  selection: false,
  searchLabelWidth: 90,
  column: singleCustomFloorCols
}
